<!--For some reason passing null | undefine to formControl throws an error
and it is necessary to support both setups -->
<ng-container *ngIf="formControl">
	<p-calendar
		[formControl]="formControl"

		[inputId]="inputId"
		[placeholder]="placeholder"
		[selectionMode]="selectionMode"
		[showIcon]="showIcon"
		[dateFormat]="dateFormat"
		[appendTo]="appendTo"
		[readonlyInput]="false"
		[showTime]="showTime"
		[showSeconds]="showSeconds"
		[hourFormat]="hourFormat"
		[minDate]="minDate"
		[defaultDate]="_defaultDate"
		(onSelect)="handleValueChange($event)">
	</p-calendar>
</ng-container>

<ng-container *ngIf="!formControl">
	<p-calendar
		[inputId]="inputId"
		[placeholder]="placeholder"
		[selectionMode]="selectionMode"
		[showIcon]="showIcon"
		[dateFormat]="dateFormat"
		[appendTo]="appendTo"
		[readonlyInput]="false"
		[showTime]="showTime"
		[showSeconds]="showSeconds"
		[hourFormat]="hourFormat"
		[minDate]="minDate"
		[defaultDate]="_defaultDate"
		(onSelect)="handleValueChange($event)">
	</p-calendar>
</ng-container>
