import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {CalendarModule} from 'primeng/calendar';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {DateTime} from 'luxon';
import {NgIf} from '@angular/common';

@Component({
	selector: 'he-calendar',
	standalone: true,
	imports: [
		CalendarModule,
		ReactiveFormsModule,
		NgIf
	],
	templateUrl: './calendar.component.html',
	styleUrl: './calendar.component.scss',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CalendarComponent),
			multi: true,
		},
	],
})
export class CalendarComponent implements ControlValueAccessor {
	@Input() formControl: FormControl = null;
	@Input() showIcon: boolean = false;
	@Input() dateFormat: string = 'dd/mm/yy'; // 'dd-mm-yy'
	@Input() appendTo: string = 'body';
	@Input() showTime: boolean = false;
	@Input() showSeconds: boolean = false;
	@Input() hourFormat: string = '24';
	@Input() minDate!: Date;

	@Input() inputId: string | undefined;
	@Input() placeholder: string | ''
	@Input() selectionMode: 'single' | 'range' = 'single'; // | 'multiple' is not support atm

	/**
	 * Use Luxon DateTime for defaultDate. This is important to avoid automatic timezone
	 * adjustments done by JavaScript Date object. This allows the date to be treated as a
	 * specific timezone, UTC, or a simple local time: in our case, currently, as UTC.
	 *
	 * @summary Set default to local midnight 00:00:00
	 */
	defaultDate: DateTime = DateTime.local().set({hour: 0, minute: 0, second: 0});

	_defaultDate: Date = new Date()

	@Output() onSelect: EventEmitter<DateTime | [DateTime, DateTime]> = new EventEmitter(); // Emit Luxon DateTime

	value!: DateTime;
	onChange = (value: any) => {
	};
	onTouched = () => {
	};

	private range: [DateTime, DateTime] | null = null;

	constructor() {
		this._defaultDate.setHours(0, 0, 0); // Default time to 00:00:00
	}


	// region ControlValueAccessor methods

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// TODO - Handle disabling the component
	}

	// endregion

	handleValueChange(event: any): void {
		if (this.selectionMode === 'single') {
			// Convert JavaScript Date to Luxon DateTime
			const selectedDate = DateTime.fromJSDate(event)
				.setZone('local', {keepLocalTime: true})

			this.value = selectedDate;

			// Notify Angular FormGroup - remove any offset in order to match what was shown on UI
			//  i.e. it is assumed that the input is UTC.
			this.onChange(selectedDate.toISO({includeOffset: false}) + 'Z');

			// Emit Luxon DateTime
			this.onSelect.emit(selectedDate);
		} else if (this.selectionMode === 'range') {
			const selectedDate = DateTime.fromJSDate(event)
				.setZone('local', {keepLocalTime: true})

			if (!this.range) {
				this.range = [selectedDate, null];
			} else if (!this.range[1]) {
				this.range[1] = selectedDate;

				this.onChange([
					this.range[0].toISO({includeOffset: false}) + 'Z',
					this.range[1].toISO({includeOffset: false}) + 'Z'
				]);

				this.onSelect.emit(this.range);

				this.range = null; // Reset for the next selection
			}
		}

	}

	/**
	 * Get Luxon format based on input dateFormat and showTime
	 */
	private getLuxonFormat(): string {
		return this.showTime ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy';
	}
}
