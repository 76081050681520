<he-skeleton-draw-catalogue [loading]="loading" [viewType]="viewLayout"></he-skeleton-draw-catalogue>

<!--layout={{viewLayout}} on init since this is intentionally NOT being bound to changes-->
<p-dataView [style.display]="!loading ? 'block' : 'none'" [totalRecords]="totalRecords" [emptyMessage]="tableMessage" [value]="data" [paginator]="true" lazy=true
			[rows]="10" (onLazyLoad)="getTableData($event)" [layout]="viewLayout" [loading]="loading" (onChangeLayout)="onChangeLayout($event)">
	<p-header style="display: flex; justify-content: space-between;">
		<div>
			<p-selectButton *ngIf="drawType === drawTypeEnum.ACTIVE" [options]="tableFilterOpts"
							[ngModel]="selectedPageFilter" [style]="{'min-width': '150px'}"
							(ngModelChange)="onGameFilterChange($event)"></p-selectButton>
		</div>

		<p-dataViewLayoutOptions></p-dataViewLayoutOptions>
	</p-header>

	<ng-template let-draws pTemplate="list">
		<ng-container *ngFor="let draw of draws;">
			<he-draw-card [gameDraw]="draw" [drawType]="drawType" (refreshCatalogueEvent)="onRefreshCatalogue()"></he-draw-card>
		</ng-container>
	</ng-template>

	<ng-template let-draws pTemplate="grid">
		<!--It was found that when in gridItem, an empty draw obj is generated to fill 2 x 2; however this generated
		an empty he-draw-card atop - adding *ngIf="draw.drawID" ensures that only defined "draw" create a card,
		which in turns forces any empty slots at the end of the list.-->
		<ng-container *ngFor="let draw of draws;">
			<he-draw-card *ngIf="draw?.drawID" [gameDraw]="draw" [drawType]="drawType" (refreshCatalogueEvent)="onRefreshCatalogue()"></he-draw-card>
		</ng-container>
	</ng-template>
</p-dataView>
