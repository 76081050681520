// Features DB and UI column names mapping

//region CROSS COMPONENTS (Player Trail, Participation History, etc.)

export const USERNAME = {DB: 'username', UI: 'Username'};
export const FULL_NAME = {DB: 'fullName', UI: 'Full Name'};
export const MOBILE = {DB: 'mobileNumber', UI: 'Mobile Number'};
export const EMAIL = {DB: 'email', UI: 'Email Address'};

export const PLAYER_ID = {DB: 'playerID', UI: 'Player ID'};
export const PLAYER_KYC_STATE = {DB: 'playerKYCState', UI: 'Player KYC Status'};
export const PLAYER_KYC_STATE_ID = {DB: 'playerKYCStateID', UI: 'Player KYC Status ID'};
export const PLAYER_KYC_ENTRY_ID = {DB: 'playerKYCEntryID', UI: 'Player KYC Entry ID'};
export const KYC_STATUS = {DB: 'kycState', UI: 'KYC Status'};
export const PART_ID = {DB: 'participationID', UI: 'Participation ID'};
export const TRANS_TYPE = {DB: 'transactionType', UI: 'Trans. Type'};
export const TRANS_TYPE_ID = {DB: 'transactionTypeID', UI: TRANS_TYPE.UI};
export const COUPON_ID = {DB: 'couponID', UI: 'Coupon ID'};
export const DATE_TIME = {
	DB: 'dateTime', UI: 'Date & Time',
	UI_DATE_PLACED: 'Date Placed',
	UI_SKD_REN: 'Scheduled Renewal'
};
export const TIME_STAMP = {DB: 'timestamp', UI: 'Date & Time', UI_timeStamp: 'Timestamp'};

export const CREATION_DATE = {DB: 'creationDate', UI: 'Creation Date'};
export const GAME_NAME = {DB: 'gameName', UI: 'Game Name'};
export const GAME_NAME_ID = {DB: 'gameGroupID', UI: 'Game Name'};
export const GAME_TYPE = {DB: 'gameType', UI: 'Game Type'};
export const GAME_TYPE_ID = {DB: 'gameTypeID', UI: 'Game Type'};

//endregion

// User

export const DEFAULT_ID = 'id';
export const NAME = {DB: 'name', UI: 'Name'};
export const SURNAME = {DB: 'surname', UI: 'Surname'};
export const USER_GROUP = {DB: 'userGroups', UI: 'Groups'};
export const USER_ROLE = {DB: 'userRoleDisplayName', UI: 'Role'};
export const USER_ROLE_ID = {DB: 'userRoleID', UI: 'Role'};
export const OPERATORS = {DB: 'operators', UI: 'Operators'};
export const OPERATORS_ID = {DB: 'tenantID', UI: 'Operators'};
export const IS_OPERATOR_USER = {DB: 'isTenantUser', UI: 'Operator User'};
export const PW_EXPIRED = {DB: 'passwordExpired', UI: 'Password Expired'};
export const IS_RESTRICTED = {DB: 'isRestricted', UI: 'Restricted'};
export const REQUIRE_PW_CHANGE = {DB: 'requirePasswordChange', UI: 'Require Password'};
export const IS_ACTIVE = {DB: 'isActive', UI: 'Is Active', UI_toggle: 'Activate / Deactivate'};
export const VIEW_LOGS = {DB: 'viewLogs', UI: 'View Logs'};

export const USER_ID = {DB: DEFAULT_ID, UI: 'User ID'};
// export const  = {DB: , UI: ''};

// UserLogs

export const USER_LOGS_RIGHTS_ID = {DB: 'id', UI: 'Access Logs ID'};
export const USER_LOGS_ACTION_NAME = {DB: 'action.name', UI: 'Action Name'};
export const USER_LOGS_DATE = {DB: 'timestamp', UI: 'Date Logged'};
export const USER_LOGS_HAS_RIGHTS = {DB: 'hasRightsToAccess', UI: 'Has Rights To Access'};
export const USER_LOGS_VIEW_RESPONSE = {DB: 'userID', UI: 'View Data'};


// Player Trail
export const AGENT_ID = {DB: 'agentID', UI: 'Agent ID'};
export const AGENT_NAME = {DB: 'agentName', UI: 'Agent Name'};
export const TRANS_ID = {DB: 'transactionID', UI: 'Trans. ID'};
export const TICKET_NUM = {DB: 'couponIdentifier', UI: 'Ticket Number'}; // or use "Coupon Identifier" for UI?
export const MONETARY_DR = {DB: 'debitAmount', UI: 'Monetary DR'};
export const MONETARY_CR = {DB: 'creditAmount', UI: 'Monetary CR'};
export const NON_MONETARY_DR = {DB: 'nonMonetaryDebit', UI: 'Non-Monetary DR'};
export const NON_MONETARY_CR = {DB: 'nonMonetaryCredit', UI: 'Non-Monetary CR'};
export const NON_MONETARY_BAL = {DB: 'nonMonetaryBalance', UI: 'Non-Monetary Balance'};

// Players
export const LIFETIME_VAL = {DB: 'lifetimeValue', UI: 'Lifetime Value'};
export const TOTAL_BETS = {DB: 'totalBets', UI: 'Total Bets'};
export const PLAYER_TYPE = {DB: 'playerType', UI: 'Player Type'};
export const PLAYER_TYPE_ID = {DB: 'playerRegistrationTypeID', UI: 'Player Type'};

export const SOURCE_TYPE = {DB: 'sourceType', UI: 'Source'};
export const SOURCE_ID = {DB: 'sourceID', UI: 'Source'};
export const SOURCE_NAME = {DB: 'sourceFullName', UI: 'Source'};
export const LAST_GAME_SESSION_DATE = {DB: 'lastGameSessionDate', UI: 'Last Game Session Date'};


// Individual Player
export const TENANT_NAME = {DB: 'tenantName', UI: 'Operator'};
export const TENANT_BRAND = {DB: 'tenantBrand', UI: 'Brand'};
export const TENANT_BRAND_ID = {DB: 'tenantBrandID', UI: 'Brand'};
export const TENANT_ID = {DB: 'tenantID', UI: 'Operator'};
export const EXTERNAL_PLAYER_ID = {DB: 'playerExtID', UI: 'External Player ID'};
export const PLAYER_USERNAME = {DB: 'username', UI: 'Username'};
export const PLAYER_FULL_NAME = {DB: 'fullName', UI: 'Full Name'};

export const LAST_LOGIN = {DB: 'lastLoginDate', UI: 'Last Session Date Time'};
export const FREE_TICKET_COUNT = {DB: 'freeTicketCount', UI: 'Free Tickets'};
export const REWARD_POINTS = {DB: 'rewardPoints', UI: 'Reward Points'};

// Reward Point tab
export const REWARD_TRANS_ID = {DB: 'rewardTransactionID', UI: 'Reward Transaction ID'};
export const REWARD_ID = {DB: 'rewardSchemeIDF', UI: 'Reward ID'};
export const COMMENT = {DB: 'comment', UI: 'Comment'};
export const COMMENT_2 = {DB: 'reason', UI: COMMENT.UI};
export const REWARD_POINTS_DEBIT = {DB: 'rewardPointsDR', UI: 'Amount DR'};
export const REWARD_POINTS_CREDIT = {DB: 'rewardPointsCR', UI: 'Amount CR'};

// KYCComponent

export const KYC_TRANS_ID = {DB: 'balanceTransactionID', UI: 'Transactional ID'};
export const DOC_ID = {DB: 'playerKYCDocumentsID', UI: 'Document ID'};
export const DOC_TYPE = {DB: 'documentType', UI: 'Document Type'};
export const KYC_STATE_ID = {DB: 'KYCStateID', UI: 'KYC Status'};

export const KYC_DOCUMENT_EXTERNAL_ID = {DB: 'playerKYCEntryID', UI: 'External ID'};
export const KYC_DOCUMENT_STATUS_ID = {DB: 'kycDocumentStateID', UI: 'Document Status'};
export const KYC_DOCUMENT_STATUS = {DB: 'kycDocumentState', UI: 'Document Status'};
export const KYC_DOCUMENT_TYPE_ID = {DB: 'kycDocumentTypeID', UI: 'Document Type'};
export const KYC_DOCUMENT_TYPE = {DB: 'kycDocumentType', UI: 'Document Type'};
export const KYC_DOCUMENT_NUMBER = {DB: 'documentIdentifier', UI: 'Document Number'};
export const KYC_DOCUMENT_CREATION_DATE = {DB: 'createdAt', UI: 'Creation Date'};
export const KYC_DOCUMENT_EXPIRY_DATE = {DB: 'expiryDate', UI: 'Expiry Date'};
export const KYC_DOCUMENT_COMMENT = {DB: 'comments', UI: 'Comment'};

// Affiliation

export const AFFILIATION_REFEREE_ID = {DB: 'refereeID', UI: 'Referee'};
export const AFFILIATION_REFEREE_NAME = {DB: 'refereeName', UI: 'Referee'};
export const AFFILIATION_REFERRED_AT = {DB: 'referredAt', UI: 'Linked Date and Time'};
export const AFFILIATION_TOTAL_MONETARY_BETS = {DB: 'totalMonetaryBets', UI: 'Total Monetary Bets'};
export const AFFILIATION_TOTAL_MONETARY_DEPOSITS = {DB: 'totalMonetaryDeposits', UI: 'Total Monetary Deposits'};
export const AFFILIATION_FIRST_DEPOSIT_DATE = {DB: 'firstDepositDate', UI: 'First Deposit Date'};
export const AFFILIATION_STATUS = {DB: 'status', UI: 'Status'};

// SessionsTab

export const SESS_ID = {DB: 'sessionID', UI: 'Session ID'};
export const LOGIN_DATE = {DB: 'loginDate', UI: 'Login Date'};
export const LOGOUT_DATE = {DB: 'logoutDate', UI: 'Logout Date'};
export const APP_TYPE = {DB: 'applicationType', UI: 'Application'};
export const PLATFORM_TYPE = {DB: 'platformType', UI: 'Platform Type'};
export const DEVICE_TYPE = {DB: 'deviceType', UI: 'Device Type'};
export const IP_ADDR = {DB: 'ipAddress', UI: 'IP Address'};
export const COUNTRY_NAME = {DB: 'countryName', UI: 'Country'};

// AccountRestrictionsTab

export const RESTRICTION_ID = {DB: 'playerRestrictionID', UI: 'Restriction ID'};
export const RESTRICTION_TYPE = {DB: 'restrictionName', UI: 'Restriction Type'};
export const RESTRICTION_TYPE_ID = {DB: 'playerRestrictionTypeID', UI: RESTRICTION_TYPE.UI};
export const ORIGINATING_CHAN = {DB: 'originatingChannel', UI: 'Originating Channel'};
export const PLACED_BY = {DB: 'placedBy', UI: 'Placed By'};
export const DATE_PLACED = {DB: 'datePlaced', UI: 'Date Placed'};
export const DATE_EXPIRY = {DB: 'dateExpiry', UI: 'Expiry Date'};
export const SCHEDULED_REMOVAL = {DB: 'scheduledRemoval', UI: 'Scheduled Removal'};
export const RESTRICTION_IS_ACTIVE = {DB: 'isActive', UI: IS_ACTIVE.UI, UI_toggle: IS_ACTIVE.UI_toggle};

// SubscriptionsTab

export const SUBSCRIPTION_ID = {DB: 'recurringPurchaseID', UI: 'Subscription ID'};
export const SUBSCRIPTION_NUMBERS = {DB: 'numbers', REFACTORED_DB: 'numbersStr', UI: 'Numbers'};
export const SUBSCRIPTION_DURATION = {DB: 'duration', UI: 'Duration'};
export const SUBSCRIPTION_STATUS = {DB: 'status', UI: 'Status'};
export const SUBSCRIPTION_STATUS_ID = {DB: 'subscriptionStatusID', UI: SUBSCRIPTION_STATUS.UI};
export const START_DATE = {DB: 'startDate', UI: 'Start Date'};
export const END_DATE = {DB: 'endDate', UI: 'End Date'};

// MessageTab

export const MSG_ID = {DB: 'messageID', UI: 'Message ID'};
export const MSG_SUBJECT = {DB: 'subject', UI: 'Subject'};
export const MSG_DATE = {DB: 'date', UI: 'Date'};


// participationHistoryComponent
export const TICKET_TYPE = {DB: 'ticketType', UI: 'Ticket Type'};
export const NON_MONETARY_RETURN = {DB: 'nonMonetaryReturn', UI: 'Non-monetary Return', UI_ABBR: 'NM Return'};
export const DRAW_ID = {DB: 'drawID', UI: 'Draw ID'};
export const SELECTION = {DB: 'selection', UI: 'Selection'};
export const DRAWN = {DB: 'drawn', UI: 'Drawn'};
export const PARTICIPATION_RES = {DB: 'participationResult', UI: 'Result'};
export const RETURN_AMOUNT = {DB: 'returnAmount', UI: 'Return'};
export const STAKE_AMOUNT = {DB: 'stakeAmount', UI: 'Stake'};
export const AUTH_STATUS_ID = {DB: 'authorisationStatusID', UI: 'Win Auth'};
export const AUTH_STATUS_NAME = {DB: 'authorisationStatusName', UI: 'Win Auth'};


// DrawSales

export const DRAW_LINES = {DB: 'drawLines', UI: 'Lines in Draw'};
export const PAID_LINES = {DB: 'paidLines', UI: 'Paid Lines in Draw'};
export const FREE_LINES = {DB: 'freeLines', UI: 'Free Lines Played'};
export const WINNING_LINES = {DB: 'winningLines', UI: 'Winning Lines'};
export const DRAW_DATE = {DB: 'drawDate', UI: 'Draw Date'};
export const DRAW_STATUS = {DB: 'drawStatus', UI: 'Draw Status'};
export const DRAW_STATUS_ID = {DB: 'drawStatusID ', UI: 'Draw Status'};
export const DRAW_EXPANDED_STATUS = {DB: 'drawExpandedStatus', UI: 'Draw Status'};
export const DRAW_EXPANDED_STATUS_ID = {DB: 'drawExpandedStatusID ', UI: 'Draw Status'};
export const DRAW_RESULT = {DB: 'results', UI: 'Draw Result'};
export const PRIZE_MONEY = {DB: 'prizeMoneyAwarded', UI: 'Prize Money'};

// Winner Breakdown
export const WIN_BREAKDOWN_PRIZE_TIER = {DB: 'prizeTier', UI: 'Prize Tier'};
export const WIN_BREAKDOWN_CRITERIA = {DB: 'matchingCriteria', UI: 'Matching Criteria'};
export const WIN_BREAKDOWN_NUM_WINNERS = {DB: 'numberOfWinners', UI: 'Number of Winners'};
export const WIN_BREAKDOWN_PRIZE_PER_WINNER = {DB: 'prizePerWinner', UI: 'Prize per Winner'};
export const WIN_BREAKDOWN_TIER_MONETARY_EXPOSURE = {DB: 'tierMonetaryExposure', UI: 'Tier Monetary Exposure'};

// WalletTransactions

export const WALLET_TRANS_ID = {DB: 'balanceTransactionID', UI: 'ID'};
export const WALLET_TRANS_TYPE = {DB: 'transactionType', UI: 'Transaction Type'};
export const WALLET_TRANS_TYPE_ID = {DB: 'transactionTypeID', UI: WALLET_TRANS_TYPE.UI};
export const ACTION_ID = {DB: 'balanceActionID', UI: 'Action ID'};
export const WALLET_TRANS_DATE = {DB: 'timestamp', UI: 'Date'};
export const WALLET_VAT = {DB: 'vat', UI: 'VAT'};
export const WALLET_TYPE = {DB: 'walletType', UI: 'Wallet Type'};
export const WALLET_TYPE_ID = {DB: 'playerBalanceTypeID', UI: WALLET_TYPE.UI};
export const WALLET_AMOUNT = {DB: 'amount', UI: 'Amount'};
export const WALLET_BALANCE = {DB: 'walletBalance', UI: 'Wallet Balance'};
export const WALLET_TOTAL_BALANCE = {DB: 'totalBalance', UI: 'Total Balance'};
export const WALLET_BAL_BEFORE = {DB: 'balanceBefore', UI: 'Balance Before'};
export const WALLET_BAL_AFTER = {DB: 'balanceAfter', UI: 'Balance After'};
export const WALLET_INVOICE_NOTE = {DB: 'invoiceNumber', UI: 'Invoice Note'};
export const WALLET_CREDIT_NOTE = {DB: 'creditNoteNumber', UI: 'Credit Note'};

export const WALLET_INVOICE_CREDIT_NOTE = {DB: 'taxDocumentNumber', UI: 'Invoice/Credit Note'};


// GatewayTransactions

export const GATEWAY_TRANS_ID = {DB: 'paymentOrderID', UI: 'ID'};
export const GATEWAY_TRANS_WALLET_ID = {DB: 'balanceTransactionID', UI: 'Wallet ID'};
export const GATEWAY_TRANS_CART_ID = {DB: 'purchaseCartID', UI: 'Cart ID'};
export const GATEWAY_TRANS_STATUS = {DB: 'paymentState', UI: 'Status'};
export const GATEWAY_TRANS_STATUS_ID = {DB: 'paymentStateID', UI: GATEWAY_TRANS_STATUS.UI};
export const GATEWAY_TRANS_CREATED_DATE = {DB: 'createdDate', UI: 'Date Created'};
export const GATEWAY_TRANS_PAYMENT_PROVIDER = {DB: 'thirdParty', UI: 'Payment Provider'};
export const GATEWAY_TRANS_PAYMENT_PROVIDER_ID = {DB: 'thirdPartyID', UI: GATEWAY_TRANS_PAYMENT_PROVIDER.UI};
export const GATEWAY_TRANS_PAYMENT_METHOD = {DB: 'paymentMethodType', UI: 'Payment Method'};
export const GATEWAY_TRANS_PAYMENT_METHOD_ID = {DB: 'paymentMethodTypeID', UI: GATEWAY_TRANS_PAYMENT_METHOD.UI};
export const GATEWAY_TRANS_PAYMENT_REF = {DB: 'gatewayOrderReference', UI: 'Payment Reference'};
export const GATEWAY_TRANS_TYPE = {DB: 'transactionType', UI: 'Transaction Type'};
export const GATEWAY_TRANS_TYPE_ID = {DB: 'transactionTypeID', UI: GATEWAY_TRANS_TYPE.UI};
export const GATEWAY_TRANS_AMOUNT = {DB: 'amount', UI: 'Amount'};
export const GATEWAY_TRANS_COMMENT = {DB: 'comments', UI: 'Comments'};


// BankCards (TableDialog)

export const CARD_ID = {DB: 'playerPaymentAccountID', UI: 'Card ID'};
export const CARD_TYPE = {DB: 'paymentMethodName', UI: 'Card Type'};
export const CARD_TYPE_ID = {DB: 'paymentMethodID', UI: CARD_TYPE.UI};
export const CARD_EXPIRY_DATE = {DB: 'expiry', UI: 'Expiry Date'}; // UI Generated!
export const CARD_NUMBER = {DB: 'accountReference', UI: 'Card Number'};
export const CARD_ADDED_DATE = {DB: 'dateCreated', UI: 'Added Date'};
export const CARD_LAST_USED_DATE = {DB: 'lastUsed', UI: 'Last Used'};
export const CARD_IS_DEFAULT = {DB: 'isDefault', UI: 'Default Card'};

// BankDetails (TableDialog)

export const BANK_ACCOUNT_PAYMENT_ID = {DB: 'playerPaymentAccountID', UI: 'Payment Account ID'};
export const BANK_ACCOUNT_BENEFICIARY = {DB: 'beneficiaryName', UI: 'Beneficiary Name'};
export const BANK_ACCOUNT_BANK_NAME = {DB: 'bankName', UI: 'Bank Name'};
export const BANK_ACCOUNT_BANK_BRANCH = {DB: 'bankBranch', UI: 'Bank Branch'};
export const BANK_ACCOUNT_BANK_STATE = {DB: 'bankState', UI: 'Bank State'};
export const BANK_ACCOUNT_BANK_CITY = {DB: 'bankCity', UI: 'Bank City'};
export const BANK_ACCOUNT_COUNTRY = {DB: 'bankBranchCountryName', UI: 'Bank Country'};

export const BANK_ACCOUNT_NUMBER = {DB: 'bankAccountNumber', UI: 'Account No.'};
export const BANK_ACCOUNT_SORT_CODE = {DB: 'sortCode', UI: 'Sort Code'};
export const BANK_ACCOUNT_SWIFT_CODE = {DB: 'swiftCode', UI: 'SWIFT'};
export const BANK_ACCOUNT_IBAN = {DB: 'iban', UI: 'IBAN'};
export const BANK_ACCOUNT_IFSC = {DB: 'ifsc', UI: 'IFSC'};
export const BANK_ACCOUNT_ROUTING_NUMBER = {DB: 'routingNumber', UI: 'Routing Number'};
export const BANK_ACCOUNT_NAT_ID_NUMBER = {DB: 'nationalIDNumber', UI: 'National ID No.'};
export const BANK_ACCOUNT_WITHDRAWAL_METHOD = {DB: 'withdrawalMethod', UI: 'Withdrawal Method'};
export const BANK_ACCOUNT_ACCOUNT_REF = {DB: 'accountReference', UI: 'Account Reference'};
export const BANK_ACCOUNT_DATE_CREATED = {DB: 'dateCreated', UI: 'Date Created'};
export const BANK_ACCOUNT_DATE_LAST_USED = {DB: 'lastUsed', UI: 'Last Used'};
export const BANK_ACCOUNT_DATE_LAST_UPDATED = {DB: 'lastUpdated', UI: 'Last Updated'};
export const BANK_ACCOUNT_IS_DEFAULT = {DB: 'isDefault', UI: 'isDefault'};


// ExchangeHouses (TableDialog)

export const EXCHANGE_HOUSE_ID = {DB: 'playerPaymentAccountID', UI: BANK_ACCOUNT_PAYMENT_ID.UI};
export const EXCHANGE_HOUSE_BENEFICIARY = {DB: 'beneficiaryName', UI: 'Beneficiary Name'};
export const EXCHANGE_HOUSE_MOBILE_NUM = {DB: 'mobileNumber', UI: 'Mobile Number'};
export const EXCHANGE_HOUSE_BANK_NAME = {DB: 'bankName', UI: 'Bank Name'};
export const EXCHANGE_HOUSE_BANK_STATE = {DB: 'bankState', UI: 'Bank State'};
export const EXCHANGE_HOUSE_BANK_CITY = {DB: 'bankCity', UI: 'Bank City'};
export const EXCHANGE_HOUSE_COUNTRY = {DB: 'bankBranchCountryName', UI: BANK_ACCOUNT_COUNTRY.UI};
export const EXCHANGE_HOUSE_NAT_ID_NUMBER = {DB: 'nationalIDNumber', UI: 'National ID No.'};
export const EXCHANGE_HOUSE_WITHDRAWAL_METHOD = {DB: 'withdrawalMethod', UI: 'Withdrawal Method'};
export const EXCHANGE_HOUSE_ACCOUNT_REF = {DB: 'accountReference', UI: 'Account Reference'};
export const EXCHANGE_HOUSE_DATE_CREATED = {DB: 'dateCreated', UI: 'Date Created'};
export const EXCHANGE_HOUSE_DATE_LAST_USED = {DB: 'lastUsed', UI: 'Last Used'};
export const EXCHANGE_HOUSE_DATE_LAST_UPDATED = {DB: 'lastUpdated', UI: 'Last Updated'};
export const EXCHANGE_HOUSE_IS_DEFAULT = {DB: 'isDefault', UI: 'isDefault'};


// ExchangeHouses (TableDialog)

export const E_WALLET_ID = {DB: 'playerPaymentAccountID', UI: BANK_ACCOUNT_PAYMENT_ID.UI};
export const E_WALLET_BENEFICIARY = {DB: 'holderName', UI: 'Holder Name'};
export const E_WALLET_PAYMENT_METHOD = {DB: 'paymentMethod', UI: 'Provider Name'};
export const E_WALLET_PAYMENT_METHOD_ID = {DB: 'paymentMethodID', UI: E_WALLET_PAYMENT_METHOD.UI};
export const E_WALLET_ACCOUNT_REF = {DB: EXCHANGE_HOUSE_ACCOUNT_REF.DB, UI: EXCHANGE_HOUSE_ACCOUNT_REF.UI};
export const E_WALLET_DATE_CREATED = {DB:  EXCHANGE_HOUSE_DATE_CREATED.DB, UI: EXCHANGE_HOUSE_DATE_CREATED.UI};
export const E_WALLET_DATE_LAST_USED = {DB: EXCHANGE_HOUSE_DATE_LAST_USED.DB, UI: EXCHANGE_HOUSE_DATE_LAST_USED.UI};
export const E_WALLET_DATE_LAST_UPDATED = {DB: EXCHANGE_HOUSE_DATE_LAST_UPDATED.DB, UI: EXCHANGE_HOUSE_DATE_LAST_UPDATED.UI};
export const E_WALLET_IS_DEFAULT = {DB: EXCHANGE_HOUSE_IS_DEFAULT.DB, UI: EXCHANGE_HOUSE_IS_DEFAULT.UI};
export const E_WALLET_IS_DELETED = {DB: 'isDeleted', UI: 'IsDeleted'};





// Payment Providers Fallback

const PAYMENT_PROVIDER_ID = 'paymentServiceProviderID';

export const FALLBACK_ID = {DB: PAYMENT_PROVIDER_ID, UI: 'Fallback ID'};
export const FALLBACK_ORDER = {DB: 'fallbackOrder', UI: 'Order'};
export const FALLBACK_NAME = {DB: 'name', UI: 'Fallback Name'};
export const FALLBACK_PLAYER_COUNT = {DB: 'currentNumberOfPlayers', UI: 'Current no. of Players'};


// Payment Providers Distribution Percentage

export const DIST_ID = {DB: PAYMENT_PROVIDER_ID, UI: 'Distribution ID'};
export const DIST_PERCENTAGE = {DB: 'distribution', UI: 'Distribution %'};
export const DIST_IS_ACTIVATED = {DB: 'isActivated', UI: 'Activated'};
export const DIST_NAME = {DB: 'name', UI: 'Name'};
export const DIST_NAME_ID = {DB: 'nameID', UI: DIST_NAME.UI};
export const DIST_PLAYER_COUNT = {DB: 'currentNumberOfPlayers', UI: FALLBACK_PLAYER_COUNT.UI};


// Withdrawal

export const WITHDRAWAL_ID = {DB: 'paymentOrderID', UI: 'Withdrawal ID'};
export const WITHDRAWAL_STATUS = {DB: 'paymentState', UI: 'Withdraw Status'};
export const WITHDRAWAL_STATUS_ID = {DB: 'paymentStateID', UI: WITHDRAWAL_STATUS.UI};
export const WITHDRAWAL_REQUEST_DATE = {DB: 'createdDate', UI: 'Request Date'};
export const WITHDRAWAL_METHOD = {DB: 'paymentMethod', UI: 'Method'};
export const WITHDRAWAL_METHOD_ID = {DB: 'paymentMethodID', UI: WITHDRAWAL_METHOD.UI};
export const WITHDRAWAL_AMOUNT = {DB: 'amount', UI: 'Amount'};


// PlayerAudit
// todo: Map these values to those used by api once impl by backend

export const PLAYER_AUDIT_ID = {DB: 'changeTrailID', UI: 'ID'};
export const PLAYER_AUDIT_PLAYER_ID = {DB: 'entityID', UI: PLAYER_ID.UI};
export const PLAYER_AUDIT_DATETIME = {DB: 'changeDate', UI: 'Date and Time Changed'};
export const PLAYER_AUDIT_CHANGED_BY = {DB: 'changedBy', UI: 'Changed By'};
export const PLAYER_AUDIT_CHANGED_FIELD = {DB: 'changeFieldDisplayName', UI: 'Item Changed'};
export const PLAYER_AUDIT_OLD_VALUE = {DB: 'oldDisplayValue', UI: 'Old Value'};
export const PLAYER_AUDIT_NEW_VALUE = {DB: 'newDisplayValue', UI: 'New Value'};

// PlayerNote (TableDialog)

export const PLAYER_NOTE_UPDATED_ON = {DB: 'updatedOn', UI: 'Updated On'};
export const PLAYER_NOTE_CHANGE_VALUE = {DB: 'value', UI: 'Note After Change'};

// Agent folders
export const BRAND_NAME = {DB: 'brandName', UI: 'Brand'};
export const BRAND_ID = {DB: 'brandID', UI: 'Brand'};
export const SOURCE = {DB: 'source', UI: 'Source'};

export const AGENT_TYPE = {DB: 'retailAgentType', UI: 'Agent Type'};
export const AGENT_TYPE_ID = {DB: 'retailAgentTypeID', UI: AGENT_TYPE.UI};

export const RETAIL_AGENT_ID = {DB: 'retailAgentID', UI: 'ID'};

//region Currency TODO: Confirm these two currently mean the same?
const currencyUI = 'Currency';

export const CURRENCY_CODE = {DB: 'currencyCode', UI: currencyUI};
export const CURRENCY_ID = {DB: 'currencyID', UI: currencyUI};
export const BALANCE = {DB: 'balance', UI: 'Balance'};

// TopUp (aka Balance) History
export const AMOUNT = {DB: 'amount', UI: 'Amount'};
export const BAL_AFTER = {DB: 'balanceAfter', UI: 'Balance'};
export const USER = {DB: 'user', UI: 'User'};

// Free Tickets

export const FREE_TICKET_GAME_ID = {DB: 'gameGroupID', UI: 'Game'};
export const FREE_TICKET_GAME_NAME = {DB: 'name', UI: 'Game'};
export const FREE_TICKET_BAL = {DB: 'currentBalance', UI: 'Current Balance'};

export const FREE_TICKET_HIST_TIMESTAMP = {DB: 'timestamp', UI: 'Date & Time'};
export const FREE_TICKET_HIST_ACTIONER = {DB: 'actionedBy', UI: 'Actioned By'};
export const FREE_TICKET_HIST_ADDED_COUNT = {DB: 'ticketsAdded', UI: 'Tickets Added'};
export const FREE_TICKET_HIST_USED_COUNT = {DB: 'ticketsUsed', UI: 'Tickets Used'};
export const FREE_TICKET_HIST_COUNT_AFTER = {DB: 'playerGameBalanceAfter', UI: 'Count After'};

// Prepaid Top-up Cards

export const TOP_UP_CARDS_BATCH_ID = {DB: 'batchID', UI: 'Batch ID'};
export const TOP_UP_CARDS_PREFIX = {DB: 'prefix', UI: 'Prefix'};
export const TOP_UP_CARDS_OPERATOR = {DB: 'operator', UI: 'Operator'};
export const TOP_UP_CARDS_GENERATED_DATE = {DB: 'generatedOn', UI: 'Generated On'};
export const TOP_UP_CARDS_GENERATED_BY = {DB: 'generatedBy', UI: 'Generated By'};
export const TOP_UP_CARDS_GENERATED = {DB: 'generated', UI: 'Generated'};
export const TOP_UP_CARDS_REMAINING = {DB: 'remaining', UI: 'Remaining'};
export const TOP_UP_CARDS_CANCELLED = {DB: 'cancelled', UI: 'Cancelled'};
export const TOP_UP_CARDS_CURRENCY = {DB: 'currency', UI: 'Currency'};
export const TOP_UP_CARDS_MONETARY_AMOUNT = {DB: 'monetaryAmount', UI: 'Monetary Amount'};
export const TOP_UP_CARDS_ASSIGNED_TO = {DB: 'assignedTo', UI: 'Assigned To'};
export const TOP_UP_CARDS_PAYMENT_RECEIVED = {DB: 'totalPaymentsReceived', UI: 'Payment Received'};
export const TOP_UP_CARDS_STATUS = {DB: 'status', UI: 'Status'};
export const TOP_UP_CARDS_STATUS_ID = {DB: 'prepaidCardBatchStateID', UI: TOP_UP_CARDS_STATUS.UI};

// Prepaid TopUp

export const BATCH_CARD_ID = {DB: 'cardID', UI: 'Card ID'};
export const BATCH_CARD_CODE = {DB: 'cardCode', UI: 'Card Code'};
export const BATCH_CARD_VALUE = {DB: 'value', UI: 'Value'};
export const BATCH_CARD_UPDATED_BY = {DB: 'updatedBy', UI: 'Updated By'};
export const BATCH_CARD_UPDATED_DATE = {DB: 'updatedAt', UI: 'DateTime Updated'};
export const  BATCH_CARD_STATUS = {DB: 'status', UI: 'Status'};
export const  BATCH_CARD_STATUS_ID = {DB: 'prepaidCardBatchCardStateID', UI: BATCH_CARD_STATUS.UI};

// Manage Payments

export const MANAGE_PAYMENTS_ID = {DB: 'batchPaymentID', UI: 'ID'};
export const MANAGE_PAYMENTS_PROVIDED = {DB: 'paymentProvided', UI: 'Payment Provided'};
export const MANAGE_PAYMENTS_AMOUNT_REMAINING = {DB: 'amountRemaining', UI: 'Amount Remaining'};
export const MANAGE_PAYMENTS_UPDATED_BY = {DB: 'updatedBy', UI: 'Updated By'};
export const MANAGE_PAYMENTS_TRANS_DATE = {DB: 'transactionDate', UI: 'Transaction Date'};
export const MANAGE_PAYMENTS_UPDATED_ON = {DB: 'updatedOn', UI: 'Updated On'};
