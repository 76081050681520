<he-base-dialog [header]="'Manual Settle'"
				[visible]="visible" (closeEvent)="closeDialog()" [rejectLabel]="'Cancel'"
				[isLoadingLookups]="isLoadingLookups" [style]="{'max-width': '550px', width: '550px'}"
				[submitLabel]="'Settle Deposit'"
				skeletonInputCount="3"
				(submitEvent)="onSubmit()" [disableSubmit]="!form.valid">
	<form [formGroup]="form" class="dialog-form-placement">
		<label>Payment Reference ID</label>
		<input  pInputText type="text" formControlName="paymentReferenceID"/>

		<label>Amount</label>
		<p-inputNumber mode="decimal" [prefix]="(transaction.currencyCode ?? '') + ' '"
					   [placeholder]="'amount'" formControlName="amount"
					   [maxFractionDigits]="0"/>

		<label>Status</label>
		<p-dropdown [options]="paymentStateLookup" formControlName="paymentState"
					appendTo="body" placeholder="Please select a Status"></p-dropdown>

		<label for="comment" style="padding-top: 8px">Comment</label>
		<textarea pInputTextarea id="comment" formControlName="comment" type="text" rows="5"></textarea>
	</form>
</he-base-dialog>
