import {Component, ComponentRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {SelectItem} from 'primeng/api';
import {SearchField} from '../search-field.model';
import {SearchFieldType} from '../search-field-type.enum';
import {AdvancedSearchOperators} from '../search-field-operators.model';
import {SearchOperator} from '../search-operator.enum';
import {SearchOperatorData} from '../search-operator-data';
import {_luxonDateTime} from '../../../../global-libraries';
import {dateToISOStr} from '../../../../utilities/general-utilities/string.utility';

@Component({
	selector: 'he-advanced-search-field',
	styleUrls: [
		'./advanced-search-field.component.scss'
	],
	templateUrl: 'advanced-search-field.component.html',
	animations: [
		trigger('animate', [
			transition(':enter', [
				style({opacity: 0, transform: 'translateX(-10px)'}),
				animate('250ms', style({opacity: 1, transform: 'translateX(0px)'}))
			]),
			transition(':leave', [
				style({opacity: 1}),
				animate('250ms', style({opacity: 0, transform: 'translateX(10px)'}))
			])
		])
	]
})
export class AdvancedSearchFieldComponent implements OnInit, OnDestroy {
	@Input() searchField: SearchField;
	@Input() searchFieldComponentRef: ComponentRef<AdvancedSearchFieldComponent>;
	@Input() searchFieldID: number;
	@Input() isFirstSearchField = true;

	@Output() destroyedComponent: EventEmitter<number> = new EventEmitter<number>();

	@HostBinding('@animate') animateSearchField = true;

	searchFieldType: typeof SearchFieldType = SearchFieldType;
	searchOperatorType: typeof SearchOperator = SearchOperator;

	dropdownSearchFieldOperators: SearchOperatorData[];
	selectedOperator: SearchOperator;

	searchAndOr: SelectItem[];
	selectedSearchAndOr: string;

	numberRangeMinValue: number;
	numberRangeMaxValue: number;

	booleanOptions: SelectItem[];

	operatorInputID: string;
	inputID: string;
	input2ID: string;

	ngOnInit() {
		if (this.searchField.type === SearchFieldType.Boolean) {
			this.booleanOptions = [
				{label: 'true', value: true},
				{label: 'false', value: false}
			];
		}

		this.searchAndOr = [
			{label: 'AND', value: 'AND'},
			{label: 'OR', value: 'OR'}
		];

		this.selectedSearchAndOr = this.searchAndOr[0].value;

		this.getSearchFieldOperatorsData();
		this.selectedOperator = this.dropdownSearchFieldOperators[0].value;

		this.inputID = new Date().getTime().toString();
		this.input2ID = `${this.inputID}-2`;
		this.operatorInputID = `${this.inputID}-operator`;
	}

	ngOnDestroy() {
		this.destroyedComponent.emit(this.searchFieldID);
	}

	selectSearchOperator(selectedSearchOperator: SearchOperator) {
		if (this.searchField.isRange) {
			this.clearRange(selectedSearchOperator);
		}

		this.selectedOperator = selectedSearchOperator;

		this.updateFilterString(this.searchField.searchValue, this.searchField);
	}

	updateFilterString(newValue: any, searchField: SearchField): void {
		if (newValue === undefined || newValue === null) {
			searchField.searchValue = undefined;
			searchField.filterString = undefined;
			return;
		}

		searchField.searchValue = newValue

		const property = searchField.property;
		const rawValue = searchField.searchValue;
		const operator = SearchOperatorData.item(this.selectedOperator).filterOperatorString;
		let filterString: string;

		const strFilterHelper = (_property: string, _operator: string, _value: string) => {
			return `${_property} ${_operator} "${_value}"`;
		}

		switch (searchField.type) {
			case SearchFieldType.StringDefault:
			case SearchFieldType.StringWithContains:
				if (rawValue !== '') {
					filterString = strFilterHelper(property, operator, rawValue);
				}
				break;
			case SearchFieldType.Boolean:
				filterString = `${property} ${operator} ${rawValue}`;
				break;
			case SearchFieldType.Date:
				const dateTimeFormat = 'dd/LL/yy hh:mm:ss';

				if (this.selectedOperator !== SearchOperator.BETWEEN) {
					// value should be in the form: '21/03/2023 10:24:33'
					// TODO: replace with #oDataDateStr helper
					const strDate = dateToISOStr(rawValue);
					filterString = `${property} ${operator} DATETIME "${strDate}"`;
				} else {
					if (rawValue[0] !== null && rawValue[1] !== null) {
						const strDateMin = dateToISOStr(rawValue[0]);
						const strDateMax = dateToISOStr(rawValue[1]);

						filterString = `(${property} ge DATETIME "${strDateMin}" AND ${property} le DATETIME "${strDateMax}")`;
					}
				}
				break;
			case SearchFieldType.Id:
			case SearchFieldType.Number:
			case SearchFieldType.NumberStrDefault:
				if (this.selectedOperator !== SearchOperator.BETWEEN) {
					if (this.searchField.type === SearchFieldType.NumberStrDefault) {
						filterString = `${property} ${operator} "${rawValue}"`;
					} else {
						filterString = `${property} ${operator} ${rawValue}`;
					}
				} else {
					if (rawValue[0] !== null && rawValue[1] !== null) {
						if (this.searchField.type === SearchFieldType.NumberStrDefault) {
							filterString = `(${property} ge "${rawValue[0]}" AND ${property} le "${rawValue[1]}")`;
						} else {
							filterString = `(${property} ge ${rawValue[0]} AND ${property} le ${rawValue[1]})`;
						}

						this.numberRangeMinValue = rawValue[0];
						this.numberRangeMaxValue = rawValue[1];
					}
				}
				break;
			case SearchFieldType.NumberList:
			case SearchFieldType.StringList:
				const joiner = (operator === 'ne') ? ' AND ' : ' OR ';

				if (searchField.isArraySearch) {
					if ((rawValue as any[]).length > 0) {
						filterString = (rawValue as any[]).map(temp => {
							const parsedValue = temp?.value ?? temp // Since for MultiSelect, with filter=true, value is a SelectItem obj
							return searchField.type === SearchFieldType.NumberList ?
								`${property} == ${parsedValue}` : strFilterHelper(property, '==', parsedValue);
						}).join(joiner);

						filterString = `${searchField.listProperty}.Any(${filterString})`;
					}
				} else {
					if ((rawValue as any[]).length > 0) {
						// TODO: Note, join was changed from " OR " to " AND " to deal with issue of filter short-circuiting in multiselect,
						//   short-circuiting in multiselect, e.g., (transactionTypeID ne 1 OR transactionTypeID ne 2) AND agentId eq 30
						//   and returning as EQUAL
						filterString = (rawValue as any[]).map(temp => {
							const parsedValue = temp?.value ?? temp // Since for MultiSelect, with filter=true, value is a SelectItem obj
							return searchField.type === SearchFieldType.NumberList ?
								`${property} ${operator} ${parsedValue}` : strFilterHelper(property, operator, parsedValue);
						}).join(joiner);

						filterString = `(${filterString})`;
					}
				}
				break;
			default:
				break;
		}

		searchField.filterString = filterString;
	}

	delete() {
		if (this.searchFieldComponentRef) {
			this.searchFieldComponentRef.destroy();
		}
	}

	private getSearchFieldOperatorsData() {
		const searchFieldOperators = AdvancedSearchOperators.item(this.searchField.type).slice();

		// if type is date or number,
		// remove between operator if SearchField.hasRange is not true
		if (this.searchField.type === SearchFieldType.Date || this.searchField.type === SearchFieldType.Number) {
			if (this.searchField.isRange !== true) {
				const index = searchFieldOperators.findIndex(temp => temp === SearchOperator.BETWEEN);
				if (index !== -1) {
					searchFieldOperators.splice(index, 1);
				}
			}
		}

		this.dropdownSearchFieldOperators = searchFieldOperators.map(temp => {
			return SearchOperatorData.item(temp);
		});
	}

	private setNumberRange() {
		this.searchField.searchValue = [
			this.searchField.minNumberRange,
			this.searchField.maxNumberRange
		];

		this.numberRangeMinValue = this.searchField.minNumberRange;
		this.numberRangeMaxValue = this.searchField.maxNumberRange;
	}

	// when switching from any operator to BETWEEN or vice-versa, searchValue needs to be cleared/set
	// this has to be done as on operator dropdown change the filterString is updated
	private clearRange(newOperator: SearchOperator) {
		// if date set searchValue to undefined
		if (this.searchField.type === SearchFieldType.Date) {
			if (this.selectedOperator === SearchOperator.BETWEEN || newOperator === SearchOperator.BETWEEN) {
				this.searchField.searchValue = undefined;
			}
		}

		// if number set searchValue to number range values when operator is set to BETWEEN
		// else set searchValue to undefined when operator was set to BETWEEN
		if (this.searchField.type === SearchFieldType.Number) {
			if (this.selectedOperator === SearchOperator.BETWEEN) {
				this.searchField.searchValue = undefined;
			} else if (newOperator === SearchOperator.BETWEEN) {
				this.setNumberRange();
			}
		}
	}
}
