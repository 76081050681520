import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {AppConfigService, BoErrorHandlerService, BreadcrumbService, ToastDisplayService} from '../../../../helio-core-services';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AppGuard} from '../../../guards';
import {PlayersService} from '../../../services/players.service';
import {LookupService} from '../../../services/lookup.service';
import {PlayerTransactionService} from '../../../services/player-transaction.service';
import {WithdrawalsService} from '../../../services/withdrawals.service';
import {PaymentProviderService} from '../../../services/payment-provider.service';
import {TableState, TableStateService} from '../../../services/table-state.service';
import {ResponsiveContent} from '../../../interfaces/responsive-content';
import {GatewayTransaction} from '../../../models/finance/gateway-transaction.model';
import {IndividualPlayerConstants} from '../constant/individual-player.constants';
import {forkJoin, from, Subscription} from 'rxjs';
import {ORDER_BY_URL_PARAM} from '../../../constants/constants';
import {TAKE_URL_PARAM} from '../../../constants';
import {ColumnType, DataTableActionCallback, DataTableLazyLoadEvent, SearchFieldType, ValidRows} from '../../data-table-v3';
import * as ColumnNames from '../../../constants/ui-db-name-mappings';
import {HttpParams} from '@angular/common/http';
import {ExportCSVUtility, FileDownloadUtility} from '../../../utilities';
import {BaseLookup} from '../../../interfaces/lookup-interfaces';
import {deepCopy} from '../../../utilities/general-utilities/arrays.utils';
import {TransactionType} from '../../../enums/transaction-type.enum';

/**
 * @summary these are mapped to backend paymentStateID values.
 */
const enum PaymentState {
	COMPLETE = 1,
	FAILURE = 2,
	PENDING = 3
}

@Component({
	selector: 'he-gateway-transaction',
	templateUrl: './gateway-transaction.component.html',
	styleUrls: ['./gateway-transaction.component.scss']
})
export class GatewayTransactionComponent extends ResponsiveContent<GatewayTransaction> {
	filterStorageName: keyof TableState;

	@Input() tabbedTitle = IndividualPlayerConstants.TAB_NAMES.gatewayTransactions;

	@Input() @HostBinding('attr.is-tabbed')
	isTabbedTable = false; // Represents false == 'global' and true == 'tabbed'

	@Output() updateDashboard: EventEmitter<void> = new EventEmitter();

	routePlayerID: number;
	oldRoutePlayerID = 0;

	selectedTransactions: GatewayTransaction[] = [];

	paymentStateLookup: BaseLookup[]
	statusBtnOpts: BaseLookup[]
	selectedStatusBtnOpt?: number | string

	selectedRow: GatewayTransaction

	showManualSettleDialog = false

	private routeSubs$: Subscription;
	private getTableDataSub$: Subscription;
	private fetchDependentLookupsSub$: Subscription;
	private fetchLookupsSub$: Subscription;
	private exportToCsvSub$: Subscription;
	private retryDepositSub$: Subscription;

	constructor(protected appConfigService: AppConfigService,
				private route: ActivatedRoute,
				private appGuard: AppGuard,
				private breadcrumbService: BreadcrumbService,
				private toastDisplayService: ToastDisplayService,
				private playersService: PlayersService,
				private lookupService: LookupService,
				private transactionService: PlayerTransactionService,
				private withdrawalsService: WithdrawalsService,
				private paymentProviderService: PaymentProviderService,
				protected tableStateService: TableStateService,
				protected boErrorHandlerService: BoErrorHandlerService) {
		super(appConfigService, boErrorHandlerService);
	}

	get tableFilters() {
		return this.tableStateService.getTableFilters(this.filterStorageName);
	}

	ngOnInit() {
		this.filterStorageName = this.isTabbedTable ? 'gatewayTransactionTab' : 'gatewayTransaction';

		this.initDataTable();

		if (!this.isTabbedTable) {
			this.breadcrumbService.setItems([
				{label: 'Finance'},
				{label: this.tabbedTitle}
			]);
		}

		this.routeSubs$ = this.route.paramMap.subscribe((params: ParamMap) => {
			const initTableDataFromPaymentState = () => {
				if (this.isTabbedTable) {
					this.routePlayerID = Number(params.get('id'));

					this.getTableDataForTab(this.routePlayerID);
				} else {
					this.getTableDataForGlobal();
				}
			}

			if (this.paymentStateLookup) {
				initTableDataFromPaymentState()
			} else {
				this.fetchDependentLookups(initTableDataFromPaymentState)
			}
		});
	}

	ngAfterViewInit() {
	}

	@HostListener('window:beforeunload', ['$event'])
	onBrowserUnload($event?: any): void {
		this.persistTableFilters(true);
	}

	ngOnDestroy() {
		this.releaseSubscriptions(
			this.routeSubs$, this.getTableDataSub$, this.fetchLookupsSub$,
			this.fetchDependentLookupsSub$, this.exportToCsvSub$, this.retryDepositSub$
		);

		this.onBrowserUnload();
	}

	onPaymentStateChange(event: any) {
		// When the page is first setup, clicking on the already selected opt results in delivery of null, ignore this.
		if (event === null || event === undefined) { // Note event = 0, indicates ALL
			return;
		}

		this.selectedStatusBtnOpt = event;
		this.getTableData(undefined);
	}

	fetchDependentLookups(onComplete?: () => void) {
		this.loading = true

		this.releaseSubscriptions(this.fetchDependentLookupsSub$);

		const observables: any = {
			getPaymentStates: this.lookupService.getWithdrawalStatuses(TransactionType.DEPOSIT)
		};

		this.fetchDependentLookupsSub$ = forkJoin(observables).subscribe({
			next: (res: any) => {
				// NB: getPaymentStates used for AdvancedSearch, and as a subset of SelectButton
				this.reassignLookupOpts(res.getPaymentStates, ColumnNames.GATEWAY_TRANS_STATUS_ID.DB);

				// Add an entry for All, which is not featured in lookup as it is taken as the absence of transactionType
				this.paymentStateLookup = deepCopy(res.getPaymentStates)

				const temp = deepCopy(
					res.getPaymentStates.filter(entry =>
						[PaymentState.COMPLETE, PaymentState.FAILURE, PaymentState.PENDING].includes(Number(entry.value))
					)
				)

				temp.push({
					displayName: 'All',
					label: 'All',
					name: 'All',
					paymentStateID: 0,
					value: 0
				})

				this.statusBtnOpts = temp
				this.selectedStatusBtnOpt = this.statusBtnOpts[this.statusBtnOpts.length - 1].value // Set the last el, all, by default

				if (onComplete) {
					onComplete()
				}
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	fetchLookups() {
		this.loadingLookups = true

		this.releaseSubscriptions(this.fetchLookupsSub$);

		const observables: any = {
			getPaymentMethodsTypes: this.lookupService.getPaymentMethodsTypes(),
			getPlayerTransactionTypes: this.lookupService.getPlayerTransactionTypes(),
			getCurrencies: from(this.lookupService.getPlayerNomenclature('Currencies')),
			getPSPs: this.lookupService.getPaymentServiceProviders(),
		};

		this.fetchLookupsSub$ = forkJoin(observables).subscribe({
			next: (res: any) => {
				this.reassignLookupOpts(res.getPaymentMethodsTypes, ColumnNames.GATEWAY_TRANS_PAYMENT_METHOD_ID.DB);
				this.reassignLookupOpts(res.getPlayerTransactionTypes, ColumnNames.GATEWAY_TRANS_TYPE_ID.DB);
				this.reassignLookupOpts(res.getCurrencies.result.currencies, ColumnNames.CURRENCY_ID.DB);
				this.reassignLookupOpts(res.getPSPs, ColumnNames.GATEWAY_TRANS_PAYMENT_PROVIDER_ID.DB);

				this.loadingLookups = false
			},
			error: error => {
				this.loadingLookups = false
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	initDataTable() {
		this.dataKey = ColumnNames.GATEWAY_TRANS_ID.DB;

		this.cols = [
			{
				field: ColumnNames.GATEWAY_TRANS_ID.DB,
				header: ColumnNames.GATEWAY_TRANS_ID.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_WALLET_ID.DB,
				header: ColumnNames.GATEWAY_TRANS_WALLET_ID.UI
			}
		];

		if (!this.isTabbedTable) {
			this.cols.push({
				field: ColumnNames.PLAYER_ID.DB,
				header: ColumnNames.PLAYER_ID.UI
			});
		}

		this.cols.push(
			{
				field: ColumnNames.GATEWAY_TRANS_CART_ID.DB,
				header: ColumnNames.GATEWAY_TRANS_CART_ID.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_STATUS.DB,
				header: ColumnNames.GATEWAY_TRANS_STATUS.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_CREATED_DATE.DB,
				header: ColumnNames.GATEWAY_TRANS_CREATED_DATE.UI,
				columnType: ColumnType.Date
			},
			{
				field: ColumnNames.GATEWAY_TRANS_PAYMENT_PROVIDER.DB,
				header: ColumnNames.GATEWAY_TRANS_PAYMENT_PROVIDER.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_PAYMENT_METHOD.DB,
				header: ColumnNames.GATEWAY_TRANS_PAYMENT_METHOD.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_PAYMENT_REF.DB,
				header: ColumnNames.GATEWAY_TRANS_PAYMENT_REF.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_TYPE.DB,
				header: ColumnNames.GATEWAY_TRANS_TYPE.UI
			},
			{
				field: ColumnNames.CURRENCY_CODE.DB,
				header: ColumnNames.CURRENCY_CODE.UI
			},
			{
				field: ColumnNames.GATEWAY_TRANS_AMOUNT.DB,
				header: ColumnNames.GATEWAY_TRANS_AMOUNT.UI,
				localeDecimalPlaces: 2
			},
			{
				field: ColumnNames.GATEWAY_TRANS_COMMENT.DB,
				header: ColumnNames.COMMENT.UI,
				sortable: false
			}
		);

		const settleAndRetryEnablePredicate = (transaction: GatewayTransaction) => {
			return transaction.paymentStateID !== PaymentState.COMPLETE
		}

		this.tableActions = [
			{
				menuItem: {
					label: 'Retry',
					icon: 'pi pi-replay',
					enablePredicate: settleAndRetryEnablePredicate
				},
				callback: (callbackObj: DataTableActionCallback) => {
					const selectedRow: GatewayTransaction = callbackObj.data[0];
					this.retryTransaction(selectedRow)
				}
			},
			{
				menuItem: {
					label: 'Manual Settle',
					icon: 'pi pi-calculator',
					enablePredicate: settleAndRetryEnablePredicate
				},
				callback: (callbackObj: DataTableActionCallback) => {
					this.selectedRow = callbackObj.data[0];
					this.showManualSettleDialog = true
				}
			},
			{
				menuItem: {
					label: 'Export to CSV',
					icon: 'ui-icon-insert-drive-file'
				},
				callback: (callbackObj) => {
					this.exportToCSV(callbackObj.data, callbackObj.isAllDataSelected);
				},
				isRowAction: false // implies isBulkAction
			}
		];

		this.searchFields = []

		this.searchFields.push(
			{
				property: ColumnNames.GATEWAY_TRANS_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_ID.UI,
				type: SearchFieldType.Id
			},
			{
				property: ColumnNames.GATEWAY_TRANS_WALLET_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_WALLET_ID.UI,
				type: SearchFieldType.Id
			}
		);

		if (!this.isTabbedTable) {
			this.searchFields.push({
				property: ColumnNames.PLAYER_ID.DB,
				label: ColumnNames.PLAYER_ID.UI,
				type: SearchFieldType.Id
			});
		}

		this.searchFields.push(
			{
				property: ColumnNames.GATEWAY_TRANS_CART_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_CART_ID.UI,
				type: SearchFieldType.Id
			},
			{
				property: ColumnNames.GATEWAY_TRANS_STATUS_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_STATUS_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				property: ColumnNames.GATEWAY_TRANS_CREATED_DATE.DB,
				label: ColumnNames.GATEWAY_TRANS_CREATED_DATE.UI,
				type: SearchFieldType.Date,
				isRange: true
			},
			{
				property: ColumnNames.GATEWAY_TRANS_PAYMENT_PROVIDER_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_PAYMENT_PROVIDER_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				property: ColumnNames.GATEWAY_TRANS_PAYMENT_METHOD_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_PAYMENT_METHOD_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				property: ColumnNames.GATEWAY_TRANS_PAYMENT_REF.DB,
				label: ColumnNames.GATEWAY_TRANS_PAYMENT_REF.UI,
				type: SearchFieldType.StringDefault
			},
			{
				property: ColumnNames.GATEWAY_TRANS_TYPE_ID.DB,
				label: ColumnNames.GATEWAY_TRANS_TYPE_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				property: ColumnNames.CURRENCY_ID.DB,
				label: ColumnNames.CURRENCY_ID.UI,
				type: SearchFieldType.NumberList,
				listOptions: [],
				isArraySearch: false
			},
			{
				property: ColumnNames.GATEWAY_TRANS_AMOUNT.DB,
				label: ColumnNames.GATEWAY_TRANS_AMOUNT.UI,
				type: SearchFieldType.Number
			}
		);

		this.fetchLookups();
	}

	getTableData(filterData?: DataTableLazyLoadEvent) {
		super.getTableData(filterData);

		const stateFilter = this.tableStateService.getTableFilters(this.filterStorageName);

		this.params = this.computeTableDataHttpParams(
			filterData, undefined, stateFilter?.rowsPerPage, stateFilter?.orderBy);
		this.persistTableFilters();

		this.beforeTableDataServiceCall(this.getTableDataSub$);

		this.getTransactions(this.params);
	}

	getTransactions(params: HttpParams) {
		const observable: any = this.isTabbedTable ?
			this.transactionService.getGatewayTransactions(this.routePlayerID, this.selectedStatusBtnOpt, params) :
			this.transactionService.getGatewayTransactions(undefined, this.selectedStatusBtnOpt, params);

		this.getTableDataSub$ = observable.subscribe({
			next: res => {
				this.data = res.resultSet;
				this.totalRecords = res.totalRowCount;
				this.offset = res.offset;

				if (this.totalRecords === 0) {
					this.tableMessage = this.appConfigService.tableMissingDataMessage;
				}

				this.loading = false;
			},
			error: error => {
				this.tableMessage = this.appConfigService.genericErrorMessage;
				this.boErrorHandlerService.handleError( // TODO - Re-expose once PlayerTrail error fixed
					error, error.description, 'walletTransService.getTransactions');
				this.loading = false;
			}
		});
	}

	getTableDataForTab(playerID: number): void {
		this.getTableData();
	}

	getTableDataForGlobal(): void {
		this.getTableData();
	}

	protected persistTableFilters(setOnlyInStorage = false) {
		this.tableStateService.setTableFilters(this.filterStorageName, {
			orderBy: this.params?.get(ORDER_BY_URL_PARAM),
			rowsPerPage: Number(this.params?.get(TAKE_URL_PARAM)) as ValidRows
		}, setOnlyInStorage);
	}

	exportToCSV(selectedData?: GatewayTransaction[], isAllDataSelected?: boolean) {
		super.exportToCSV(selectedData, isAllDataSelected);
		this.releaseSubscriptions(this.exportToCsvSub$)

		this.loading = true;

		const exportHttpParams = ExportCSVUtility.getHttpParams(
			this.params, selectedData, isAllDataSelected, ColumnNames.GATEWAY_TRANS_ID.DB
		);

		this.exportToCsvSub$ = this.transactionService.getGatewayTransactionsCsv(this.routePlayerID, exportHttpParams, this.cols).subscribe({
			next: (res: string) => {
				if (res !== undefined) {
					FileDownloadUtility.downloadCsv(
						res,
						this.routePlayerID ? `GatewayTransactionsForPlayer_${this.routePlayerID}` : 'GatewayTransactionForPlayers'
					);
				}

				this.loading = false;
			},
			error: error => {
				this.loading = false;
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	private retryTransaction(selectedRow: GatewayTransaction) {
		this.loading = true;
		this.releaseSubscriptions(this.retryDepositSub$)

		this.retryDepositSub$ = this.transactionService.retryDeposit(selectedRow.paymentOrderID).subscribe({
			next: (res: string) => {
				this.toastDisplayService.showSuccessToast('Deposit retry succeeded.');
				this.getTableData()
			},
			error: error => {
				this.boErrorHandlerService.handleError(error);
				this.getTableData()
			}
		});
	}

	onManualSettleClosed() {
		this.showManualSettleDialog = false
		this.selectedRow = null
		this.getTableData()
	}
}
