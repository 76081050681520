import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GatewayTransaction} from '../../../models/finance/gateway-transaction.model';
import {SettleDepositRequest} from '../../../models/player/transactions.model';
import {BaseLookup} from '../../../interfaces/lookup-interfaces';
import {BoErrorHandlerService, ToastDisplayService} from '../../../../helio-core-services';
import {ComponentHelper} from '../../../interfaces/component.helper';
import {RE_COMMENTS} from '../../../constants/constants';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PlayerTransactionService} from '../../../services/player-transaction.service';

@Component({
	selector: 'he-manual-settle-transaction',
	templateUrl: './manual-settle-transaction.component.html',
	styleUrls: [
		'./manual-settle-transaction.component.scss',
		'../../../../shared/components/dialog/common-draw-dialog-style.scss'
	]
})
export class ManualSettleTransactionComponent extends ComponentHelper implements OnInit, OnDestroy {
	private _paymentState$ = new BehaviorSubject<BaseLookup[]>(null)

	@Input() visible = false;
	@Input() transaction: GatewayTransaction;

	@Output() closeEvent: EventEmitter<void> = new EventEmitter();

	form: FormGroup
	isLoadingLookups = true
	loading = false

	private retryDepositSub$: Subscription;

	constructor(
		private transactionService: PlayerTransactionService,
		private toastDisplayService: ToastDisplayService,
		private boErrorHandlerService: BoErrorHandlerService
	) {
		super();
	}

	@Input() set paymentStateLookup(value: BaseLookup[]) {
		this._paymentState$.next(value)
	}

	get paymentStateLookup() {
		return this._paymentState$.getValue()
	}

	ngOnInit() {
		this.setFormControls();

		this._paymentState$.asObservable().subscribe({
			next: value => {
				this.isLoadingLookups = !value;
			}
		})
	}

	ngOnDestroy() {
		this.releaseSubscriptions(this.retryDepositSub$);
	}

	onSubmit() {
		const request: SettleDepositRequest = {
			gatewayOrderReference: this.form.get('paymentReferenceID').value,
			paymentStateID: this.form.get('paymentState').value,
			amount: this.form.get('amount').value,
			comment: this.form.get('comment').value,
		};

		this.loading = true;
		this.releaseSubscriptions(this.retryDepositSub$)

		this.retryDepositSub$ = this.transactionService.settleDeposit(this.transaction?.paymentOrderID, request).subscribe({
			next: (res) => {
				this.toastDisplayService.showSuccessToast('Deposit retry succeeded.');
				this.closeDialog()
			},
			error: error => {
				this.loading = false
				this.boErrorHandlerService.handleError(error);
			}
		});
	}

	closeDialog() {
		this.closeEvent.emit();
	}

	private setFormControls() {
		const paymentReferenceID = this.transaction?.gatewayOrderReference ?? ''

		const controls = {
			paymentReferenceID: new FormControl(paymentReferenceID, []),
			amount: new FormControl(this.transaction?.amount ?? '', [
				Validators.required
			]),
			paymentState: new FormControl(this.transaction?.paymentStateID ?? '', [
				Validators.required,
				Validators.min(1)
			]),
			comment: new FormControl('', [
				Validators.pattern(RE_COMMENTS),
				Validators.minLength(2),
				Validators.maxLength(2500)
			])
		};

		this.form = new FormGroup(controls);

		if (paymentReferenceID && paymentReferenceID.trim().length > 0) {
			const fc = this.form.controls['paymentReferenceID']
			fc.disable()
			fc.updateValueAndValidity()
		}
	}
}
