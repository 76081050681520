<div *ngIf="!isTabbedTable" class="header-opts">
	<he-header headerTitle="{{tabbedTitle}}"></he-header>
</div>

<he-data-table-v3 [loading]="loading || loadingLookups"
				  [dataKey]="dataKey" [columns]="cols" [data]="data" [selectedRows]="selectedTransactions"
				  (lazyLoad)="getTableData($event)" [totalRecords]="totalRecords" [offset]="offset"
				  [rowsPerPage]="tableFilters?.rowsPerPage" [restoreOrderBy]="tableFilters?.orderBy"
				  [tableActions]="tableActions" [tableMessage]="tableMessage" [searchFields]="searchFields"
				  [canExportAll]="false"
				  (exportToCsvRequested)="exportToCSV(null, false)"
				  [headerTitle]="null"
				  [isTabbedTable]="isTabbedTable" [tabbedTitle]="tabbedTitle"
				  [skeletonOverheadOptLeft]="true" [showNestedOverheadOpts]="true">
	<div style="display: flex; column-gap: 48px; justify-content: space-between; flex-wrap: wrap">
		<div class="radio-filter-wrapper">
			<ng-container *ngIf="loading; else statusSplitBtn">
				<div style="display: flex; column-gap: 12px">
					<p-skeleton width="200px" height="30px"></p-skeleton>
					<!--<p-skeleton width="160px" height="33px"></p-skeleton>
					<p-skeleton width="160px" height="33px"></p-skeleton>-->
				</div>
			</ng-container>

			<ng-template #statusSplitBtn>
				<p-selectButton [options]="statusBtnOpts"
								[ngModel]="selectedStatusBtnOpt" [style]="{'min-width': '150px'}"
								(ngModelChange)="onPaymentStateChange($event)"></p-selectButton>
			</ng-template>
		</div>
	</div>
</he-data-table-v3>

<he-manual-settle-transaction *ngIf="showManualSettleDialog" [visible]="showManualSettleDialog"
							  [transaction]="selectedRow" [paymentStateLookup]="paymentStateLookup"
							  (closeEvent)="onManualSettleClosed()"></he-manual-settle-transaction>
