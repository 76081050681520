import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import {
	DIALOG_CONFIRM_MSG_NORMAL,
	RE_COMMENTS,
} from "../../../constants/constants";
import { BehaviorSubject } from "rxjs";
import { BaseLookup } from "../../../interfaces/lookup-interfaces";

/**
 * @deprecated Use {@link ChangeStatusDialogV2Component} instead.
 */
@Component({
	selector: "he-change-status-dialog",
	styleUrls: [
		"./change-status-dialog.scss",
		"../common-draw-dialog-style.scss",
	],
	templateUrl: "./change-status-dialog.component.html",
})
export class ChangeStatusDialogComponent implements OnInit {
	@Input() visible = false;
	@Output() visibleChange: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	@Input() dialogTitle = "Change KYC Status";
	submitLabel = "Change";
	// @Input() options: StatusDialogOpts;
	@Input() data: any;
	@Input() isDisabled = false;

	@Output() dialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();
	//@Output() submitEvent: EventEmitter<KycDocumentStatusRequest> = new EventEmitter<KycDocumentStatusRequest>();

	isLoadingLookupsOrModel = false;
	form: FormGroup;
	confirmDialogMsg: string = DIALOG_CONFIRM_MSG_NORMAL;

	isRejectStatus = false;

	selectedStatusID: number;

	private _options$ = new BehaviorSubject<StatusDialogOpts>(null);

	constructor(private formBuilder: FormBuilder) {}

	get options() {
		return this._options$.getValue();
	}

	@Input() set options(value: StatusDialogOpts) {
		this._options$.next(value);
	}

	ngOnInit() {
		if (
			!this.options ||
			!this.options?.selectedStatus ||
			!this.options?.statusOptions
		) {
			this.isLoadingLookupsOrModel = true;
		}

		this.form = this.formBuilder.group({
			status: new FormControl(
				this.options?.selectedStatus
					? this.options?.selectedStatus
					: "",
			),
			comment: new FormControl("", [
				Validators.required,
				Validators.pattern(RE_COMMENTS),
				Validators.minLength(6),
				Validators.maxLength(2500),
			]),
		});

		this.form.get("status").valueChanges.subscribe((newValue) => {
			// Get status names from selected ID
			const selectedOpt: BaseLookup = this.options.statusOptions.find(
				(entry) => {
					return entry.value === this.selectedStatusID;
				},
			);

			const newOpt: BaseLookup = this.options.statusOptions.find(
				(entry) => {
					return entry.value === newValue;
				},
			);

			// Require "comments" if selected status == ‘KYC Rejected’
			this.addOrRemoveCommentsValidator(newValue);

			this.confirmDialogMsg =
				!selectedOpt || !newOpt
					? ""
					: `Are you sure you want to change from '${selectedOpt?.label}' to '${newOpt?.label}'`;

			this.selectedStatusID = newValue;
		});

		// Update selected status on form if @Input value changes and is defined
		this._options$.asObservable().subscribe({
			next: (options) => {
				if (
					options &&
					options?.selectedStatus &&
					options?.statusOptions
				) {
					this.selectedStatusID = this.options?.selectedStatus;
					this.form.get("status").setValue(options?.selectedStatus);

					this.isLoadingLookupsOrModel = false;

					this.addOrRemoveCommentsValidator(options?.selectedStatus);
				} else {
					this.form.get("status").setValue(""); // to ensure that stale values are not presented as current values
				}
			},
		});
	}

	addOrRemoveCommentsValidator(statusId: number | string) {
		const opt: BaseLookup = this.options.statusOptions.find((entry) => {
			return entry.value === statusId;
		});

		if (this.form && opt) {
			const statusLabel = opt.label;

			// Using isRejectStatus as #isRejectStatus does not update submit btn until an attempt is made to type
			this.isRejectStatus = false;

			// Require "comments" if selected status == ‘KYC Rejected’
			if (statusLabel.toLowerCase().includes("reject")) {
				this.form.get("comment").addValidators(Validators.required);
				this.isRejectStatus = true;
			} else {
				this.form.get("comment").removeValidators(Validators.required);
			}

			this.form.updateValueAndValidity();
		}
	}

	public closeDialog() {
		this.form.reset();
		this.dialogClose.emit(false);
	}

	onDialogSubmit() {
		// NB: 1. all elements within the same #kycDocumentReferences field are associated to the same playerKYCDocumentsID
		//     2. There are instances where "kycDocumentReferences" == []

		// const requestData: KycDocumentStatusRequest = {
		// 	kycStatusID: this.form.get('status').value,
		// 	playerKYCDocumentsID: undefined
		// }

		const comment = this.form.get("comment").value;

		if (comment && comment.length > 0) {
			// requestData.comment = this.form.get('comment').value;
		}

		// this.submitEvent.emit(requestData);

		this.visible = false;
	}
}

export class StatusDialogOpts {
	statusOptions: BaseLookup[];
	selectedStatus: number;
}
