/**
 * Class is a repository for the 'action' part of the URLs to be generated when making HTTP requests
 */
export class ServiceAction {
	// USER_OWN
	public static GET_ACTION_PERMISSIONS = 'GetActionPermissions';
	public static GET_PAGE_PERMISSIONS = 'GetPagePermissions';
	public static GET_REPORT_PERMISSIONS = 'GetPagePermissions';

	// GENERIC ACTIONS
	public static GENERIC_ADD = 'add';
	public static GENERIC_CREATE = 'create';
	public static GENERIC_DELETE = 'delete';
	public static GENERIC_GET = 'get';
	public static GENERIC_UPDATE = 'Update';
	public static GENERIC_GAMES = 'Games';
	public static GENERIC_POST = 'post';
	public static GENERIC_PUT = 'put';

	public static RETRY = 'Retry';
	public static SETTLE = 'Settle';

	// USER ACTIONS
	public static USER_CHANGE_PASSWORD = 'ChangePassword';
	public static USER_RESET_PASSWORD = 'ResetPassword';
	public static USER_ACTIVATE = 'Activate';
	public static USER_DEACTIVATE = 'Deactivate';

	// GAME ACTIONS
	public static GAME_GET = 'gamebyid';
	public static GAME_GAMEBYID = 'GameByID';
	public static GAME_SECONDARYGAMES = 'SecondaryGames';
	public static GAME_DEFAULTGAMES = 'DefaultGames';
	public static GAMEGROUP_GAMEGROUPBYID = 'GameGroupByID';
	public static GAMEGROUP_CURRENT_GAMEGROUP_RULE_BOARDS = 'GetCurrentGameRuleBoardsByGroupID';

	// MULTILINGUAL ACTIONS
	public static MULTILINGUAL_GETBYLANGUAGE = 'GetAllByLanguageId';

	// PLAYER MANAGEMENT ACTIONS
	public static PLAYERMANAGEMENT_GETPLAYERS = 'GetPlayersPartialData';
	public static PLAYERMANAGEMENT_GETPLAYERTRANSACTION = 'GetPlayerTransaction';
	public static PLAYERMANAGEMENT_LIFETIMEDATA = 'GetPlayerLifeTimeData';
	public static PLAYERMANAGEMENT_PLAYERTRANSACTIONS = 'GetPlayerTransactions';
	public static PLAYERMANAGEMENT_COUPONTRANSACTIONS = 'GetCouponTransactions';
	public static PLAYERMANAGEMENT_PLAYERPARTICIPATIONS = 'GetPlayerParticipations';
	public static PLAYERMANAGEMENT_PAYMENTPLANS = 'GetPlayerStaggeredPayments';
	public static PLAYERMANAGEMENT_SESSIONS = 'GetPlayerSessions';
	public static PLAYER_SESSIONS = 'GetSessions';
	public static GET_PLAYER_DETAILS = 'GetDetails';
	public static GET_PLAYER_DETAILS_ANON = 'GetAnonymousPlayerDetails';
	public static UPDATE_PLAYER_DETAILS = 'UpdateDetails';
	public static ADJUST_REWARD_POINTS = 'AdjustRewardPoints';
	public static GET_REWARD_POINT_TRANS = 'GetRewardPointTransactions';
	public static REWARD_POINTS = 'RewardPoints';
	public static ADJUST = 'Adjust';	
	public static UPDATE_PLAYER_KYC_STATE = 'kycstate';
	public static GET_PLAYER_KYC_ENTRIES = 'kYCEntries';
	public static GET_PLAYER_KYC_STATE = 'kycstate';
	public static GET_PLAYER_REFERRED_PLAYERS = 'referrals';
	public static GET_PLAYER_REFERRAL_INFORMATION = 'referrals/information';


	// PLAYER_FULL

	public static PLAYER_FULL_GET_VALIDATION_DEF = 'ApiMethodFields';


	// PLAYER BALANCE && WALLET_TRANSACTIONS

	public static GET_PLAYER_BALANCE = 'GetBalance';
	public static ADJUST_WALLET_BALANCE = 'AdjustWalletBalance';
	public static GET_WALLET_TRANSACTIONS = 'GetPlayerBalanceTransactionData';

	public static GET_GATEWAY_TRANSACTIONS = 'Orders/Deposits';

	public static PLAYER_CHANGE_PASSWORD = 'Password';

	// PLAYER COMMUNICATION PREFERENCES

	public static COMM_MESSAGE_TYPES = 'MessageTypes';
	public static COMM_TENANT_MESSAGE_CHANNEL = 'TenantMessageChannels';
	public static PLAYER_COMMUNICATION = 'communication';

	// PLAYER AUDIT

	// public static GET_PLAYER_AUDIT = 'ChangeTrails';

	// AWARD FREE TICKETS ACTIONS
	public static AWARD_TICKETS_GET_GAMES_ALL = 'GetGamesPartialData';
	public static AWARD_TICKETS_GET_GAMES_PLAYER = 'GameGroups';
	public static AWARD_TICKETS_GET_BATCHES_DATA = 'GetFreeTicketBatchesPartialData';
	public static AWARD_TICKETS_ASSIGN = 'Award';
	public static AWARD_TICKETS_DEDUCT = 'Remove';

	// KYC ACTIONS
	public static KYC_DOC_TYPES = 'KYCDocumentTypes';
	public static KYC_DOCUMENTS = 'KYCDocuments';
	public static KYC_DOC_REFERENCES = 'KYCDocumentReferences';
	public static GET_KYC_DOCUMENT = 'GetKYCDocument';
	public static DOWNLOAD_KYC_DOCUMENT = 'DownloadKYCDocument';


	// KYC
	public static GET_PLAYER_KYC_DATA = 'players/';
	public static UPDATE_PLAYER_KYC_ENTRY = '';
	public static DOWNLOAD_PLAYER_KYC_ENTRY_FILES = 'files';

	// PAYMENT ACTIONS
	public static PAYMENT_WIN_STATUSES = 'GetPaymentPlanStatuses';
	public static PAYMENT_CHANGE_STATUS = 'ChangePaymentPlanStatus';
	public static PAYMENT_CHANGE_INSTALMENT = 'ChangePaymentPlanInstalments';
	public static PAYMENT_CARDS = 'Cards';

	// WINNINGS
	public static WINNING_PARTICIPATION_STATUSES = 'GetWinningParticipationAuthorisationStatuses';
	public static WINNING_CHANGE_AUTH_STATUS = 'ChangeWinningParticipationAuthorisationStatus';

	// TENANT MANAGEMENT
	public static TENANTS_GET = 'Get';
	public static TENANTS_GET_RETAIL = 'GetRetail';
	public static TENANT_MANAGEMENT_TYPES = 'GetTenantTypes';
	public static TENANT_MANAGEMENT_CONTACT_TYPES = 'GetContactTypes';
	public static TENANT_MANAGEMENT_INTEGRATION_TYPES = 'GetIntegrationTypes';
	public static TENANT_MANAGEMENT_CREATE_CONTACT = 'CreateContact';
	public static TENANT_MANAGEMENT_CREATE_OPERATOR = 'CreateOperator';
	public static TENANT_MANAGEMENT_CREATE_BROKER = 'CreateTenantBroker';
	public static TENANT_MANAGEMENT_UPDATE_OPERATOR = 'UpdateOperator';
	public static TENANT_MANAGEMENT_UPDATE_BROKER = 'UpdateTenantBroker';

	// NOMENCLATURES
	public static NOMENCLATURES_GET_TYPES = 'GetSelectedTypes'; // returns selected types. Types selectable via searchParams.
	public static NOMENCLATURES_COUNTRIES = 'GetCountries';
	public static NOMENCLATURES_NATIONALITIES = 'GetNationalities';
	public static NOMENCLATURES_TIMEZONES = 'GetTimeZones';
	public static NOMENCLATURES_SERVERNAMES = 'GetClusters';
	public static NOMENCLATURES_GENDERS = 'GetGenders';
	public static NOMENCLATURES_LANGUAGES = 'GetLanguages';
	public static NOMENCLATURES_CURRENCY = 'GetCurrencies';

	// REPORTS
	public static REPORTS_GET_TYPES = 'GetReportTypes';
	public static REPORTS_GET_OPERATORS = 'GetOperators';
	public static REPORTS_GENERATE_AND_DOWNLOAD = 'GenerateAndDownloadReportDocument';
	public static REPORTS_DOWNLOAD = 'Download';

	// GROUP-RIGHTS
	public static GET_ACTIONS = 'Actions';

	// FINANCIAL
	public static FINANCIAL_GET_BILLING_CYCLES = 'billingcycles';
	public static FINANCIAL_GET_DAILY_BILLING_CYCLES = 'dailybillingcycles';
	public static FINANCIAL_GET_DAILY_BILLING_CYCLES_BYINVOICE = 'invoices/billingCycles';
	public static FINANCIAL_GENERATE_INVOICE = 'generateinvoice';
	public static FINANCIAL_GET_INVOICES = 'invoices';
	public static FINANCIAL_UPDATE_INVOICE = 'updateinvoice';
	public static FINANCIAL_DELETE_INVOICE = 'deleteinvoice';
	public static FINANCIAL_DELETE_INVOICE_WITH_ADJUSTMENT = 'DeleteWithAdjustment';
	public static FINANCIAL_CREATE_INVOICE = 'createinvoice';
	public static FINANCIAL_GET_CSV_FOR_SFM = 'csv';
	public static FINANCIAL_CREDIT_NOTES_GET = 'creditnotes';
	public static FINANCIAL_CREDIT_NOTES_CREATE = 'creditnotes/add';
	public static FINANCIAL_CREDIT_NOTES_UPDATE = 'creditnotes/update';
	public static FINANCIAL_CREDIT_NOTES_CSV_FOR_SFM = 'creditnotes/csv';
	public static FINANCIAL_CREDIT_NOTES_DELETE = 'creditnotes/delete';

	public static FINANCIAL_GET_BANKS = '';
	public static FINANCIAL_GET_BANK_ACCOUNTS = '';
	public static FINANCIAL_GET_BANK_ACCOUNT_BY_ID = '';
	public static FINANCIAL_CREATE_BANK_ACCOUNT = 'Create';
	public static FINANCIAL_UPDATE_BANK_ACCOUNT = 'Update';

	public static FINANCIAL_GET_BANK_STATEMENTS = '';
	public static FINANCIAL_GET_BANK_STATEMENT_BY_ID = '';
	public static FINANCIAL_CREATE_BANK_STATEMENT = 'Create';
	public static FINANCIAL_UPLOAD_BANK_STATEMENT = 'Upload';
	public static FINANCIAL_UPDATE_BANK_STATEMENT = 'Update';
	public static FINANCIAL_GET_BANK_STATEMENT_ALLOCATIONS_CSV = 'AllocationCsv';
	public static FINANCIAL_GET_BANK_STATEMENT_RECEIPTS_AND_NOMINALS_CSV = 'NominalReceiptCsv';

	public static TRANSACTIONS = 'Transactions';
	public static FINANCIAL_GET_BANK_STATEMENT_TRANSACTION_BY_ID = '';
	public static FINANCIAL_GET_BANK_STATEMENT_TRANSACTION_BY_INVOICE = 'Allocations';
	public static FINANCIAL_ADD_BANK_STATEMENT_TRANSACTIONS = 'AddTransactions';
	public static FINANCIAL_UPDATE_BANK_STATEMENT_TRANSACTION = 'UpdateTransaction';
	public static FINANCIAL_PREPARE_ALLOCATIONS = 'Allocate';
	public static FINANCIAL_PREPARE_NOMINALS = 'Nominals';
	public static FINANCIAL_COMPLETE_BANK_STATEMENT_TRANSACTIONS = 'CompleteBankTransactions';

	public static FINANCIAL_GET_BANK_NOMINAL_MAPPINGS = '';
	public static FINANCIAL_CREATE_BANK_NOMINAL_MAPPING = 'Create';
	public static FINANCIAL_UPDATE_BANK_NOMINAL_MAPPING = 'Update';
	public static FINANCIAL_DELETE_BANK_NOMINAL_MAPPING = 'Delete';

	// COMPANIES (BILLING ENTITIES)
	public static COMPANIES_GET = '';

	// LOOKUPS
	public static GET_TRANSACTION_TYPES = 'GetTransactionTypes';
	public static GET_WALLET_TYPES = 'GetWalletTypes';
	public static GET_PLAYER_REGISTRATION_TYPE = 'GetPlayerRegistrationTypes';
	public static GET_OPERATORS_BRAND = 'GetOperatorBrands';
	public static GET_OPERATORS = 'GetOperators';
	public static GET_APPLICABLE_USER_ROLES = 'GetApplicableUserRoles';
	public static GET_RESTRICTION_TYPES = 'Types';
	public static GET_RESTRICTION_INTERVAL_TYPES = 'IntervalTypes';
	public static GET_AGENT_TYPES = 'RetailAgentTypes';
	public static TOP_UP_CARDS_BATCH_STATUS = 'Batches/States';
	public static TOP_UP_CARDS_STATUS = 'Batches/Cards/States';
	public static GET_PLAYER_KYC_STATES = 'playerkycstates/';
	public static GET_KYC_DOCUMENT_STATES = 'kycdocumentstates/';
	public static GET_KYC_DOCUMENT_TYPES = 'kycdocumenttypes/';
	public static PAYMENT_STATES = 'PaymentStates';
	public static PAYMENT_METHODS = 'PaymentMethods';
	public static PAYMENT_METHODS_TYPES = 'PaymentMethodTypes';
	public static GET_GAME_TYPES = 'GameTypes';
	public static GET_TICKET_TYPES = 'TicketTypes';

	// OPERATOR SETTINGS
	public static OPERATOR_SETTINGS = 'OperatorSettings';

	// WINNING PARTICIPATIONS
	public static UPDATE_WINNING_PARTICIPATION_AUTHORISATION_STATUS = 'UpdateWinningParticipationAuthorisationStatus';

	// DRAWS
	public static DRAW_GET_BY_ID = 'GetDrawByID';
	public static DRAW_GET_PREVIOUS_DRAWS = 'GetLatestPreviousDraws'; // old name: GetAllPreviousDraws
	public static DRAW_GET_PREVIOUS_DRAWS_FOR_GAME = 'GetAllPreviousDrawsForGame'; // GetPreviousDrawsForGame
	public static DRAW_GET_PAST_TENANT_DRAWS = 'GetPastTenantDraws'; // used for export
	public static DRAW_GET_UPCOMING_DRAWS = 'GetUpcomingDrawsOverview';
	public static DRAW_GET_UPCOMING_DRAWS_FOR_GAME = 'GetUpcomingDrawOverviewForGame';
	public static DRAW_GET_ACTIVE_DRAWS_FOR_GAME = 'GetAllActiveDrawsForGame';
	public static DRAW_GET_PUBLISHABLE_DRAWS = 'UnpublishedDraw';

	public static DRAW_GET_FUTURE_TENANT_DRAWS = 'GetFutureTenantDraws'; // used for export
	public static DRAW_GET_SALES_TOTAL_BY_TENANT = 'GetDrawSalesTotalsByOperator';
	public static DRAW_GET_WINNING_STATS_BY_TENANT = 'GetDrawWinningStatsByOperator';

	public static DRAW_GET_WINNING_PLAYERS = 'GetDrawWinningPlayers';
	public static DRAW_CANCEL = 'CancelDraw';
	public static DRAW_VOID = 'VoidDraw'; // Not yet impl in BE
	public static DRAW_CHANGE_DATE = 'ChangeDrawDate';
	public static DRAW_INSERT_MANUAL_RES = 'ManualInsertResults';
	public static DRAW_APPROVE_MANUAL_RES = 'ApproveManualResults';
	public static DRAW_PUBLISH_RESULT = 'PublishResults';
	public static DRAW_GET_BOARD_INFO = 'GetBasicGameGroupInfo';
	public static DRAW_GET_INSERTED_RES = 'GetInsertedManualResult';

	public static DRAW_ACTIVE_GET_STATUSES = 'GetDrawExpandedStatus';
	public static DRAW_PREVIOUS_GET_STATUSES = 'GetDrawStatuses';

	// TICKET MANAGEMENT
	public static TICKET_MANAGEMENT_CREATE_BATCH = 'CreateBatch';
	public static TICKET_MANAGEMENT_CREATE_BATCH_GROUP = 'CreateBatchGroup';
	public static TICKET_MANAGEMENT_GET_BATCH_GROUPS = 'GetBatchGroups';
	public static TICKET_MANAGEMENT_GET_DISTRIBUTED_BATCHES = 'GetDistributedBatches';
	public static TICKET_MANAGEMENT_GET_BATCHES_BY_OPERATOR = 'GetBatchesByOperator';
	public static TICKET_MANAGEMENT_TOGGLE_ACTIVE_BATCH = 'ActivateBatch';
	public static SUBSCRIPTIONS_CANCEL = 'Cancel';
	public static SUBSCRIPTIONS_GET = '';
	public static SUBSCRIPTIONS_GET_MODELS = '';

	// RETAIL AGENTS
	public static AGENTS_RESET_PASSWORD = 'ResetPassword';
	public static AGENTS_TOPUP_BALANCE = 'TopUpBalance';
	public static AGENTS_ADJUST_BALANCE = 'AdjustBalance';
	public static AGENTS_ADJUST_BALANCE_DEFAULT_ALL = 'AdjustBalanceToDefaultAll';
	public static AGENTS_TOPUP_HISTORY = 'gettopuphistory';
	public static AGENTS_OPERATOR_CURRENCIES = 'getoperatorcurrencies';

	public static AGENTS_RECONCILIATION = 'GetRetailAgentReconciliation';
	public static AGENTS_RECONCILIATION_HISTORY = 'GetRetailAgentReconciliationHistory';
	public static AGENTS_RECONCILIATION_SALES = 'GetRetailAgentReconciliationSalesStatistics';
	public static AGENTS_RECONCILIATION_BY_DATE = 'GetRetailAgentReconciliationByDate';
	public static AGENTS_POST_RECONCILIATION_HISTORY = 'PostReconciliationHistory';
	public static AGENTS_GET_RECONCILIATION_TOTALS = 'GetRetailAgentReconciliationTotals';
	public static AGENTS_GET_RECONCILIATION_BY_ID = 'GetRetailAgentReconciliationByID';

	public static AGENTS_GAME_PARTICIPATION = 'GetGameParticipations';
	public static AGENTS_PLAYERS_TRAIL = 'GetGameTrail';

	// PLAYER RESTRICTIONS

	public static GET_PLAYER_RESTRICTIONS = 'GetPlayerRestrictions';

	// PAYMENT PROVIDERS
	public static PAYMENT_SERVICE_PROVIDER = 'PaymentServiceProviders';
	public static SERVICE_PROVIDER = 'ServiceProviders';
	public static PAYMENT_PROVIDERS_CHANGE_ORDER = 'Order';
	public static PAYMENT_PROVIDERS_DISTRIBUTE = 'Distribution';
	public static PAYMENT_PROVIDERS_ACTIVATION = 'ActivationState';
	public static PAYMENT_ROUTES = 'PaymentRoutes';

	public static GET_PAYMENT_ACCOUNTS = 'PaymentAccounts';
	public static GET_PAYMENT_ACCOUNTS_SET_DEFAULT = 'SetDefault';

	public static EXCHANGE_HOUSES = 'ExchangeHouses';
	public static E_WALLETS = 'eWallets';
	public static BANK_ACCOUNTS = 'BankAccounts';

	// WITHDRAWALS
	public static GET_BANK_DETAILS = 'GetBankDetails';
	public static GET_TRANSFER_DETAILS = 'TransferDetails';
	public static UPDATE_BANK_DETAILS = 'UpdateBankDetails';
	public static WITHDRAWAL_REQUESTS = 'PendingWithdrawalRequests';
	public static WITHDRAWAL_CHANGE_STATUS = 'ChangeWithdrawalStatus';
	public static WITHDRAWAL_DOWNLOAD_REQUESTS = 'DownloadPendingWithdrawals';
	public static WITHDRAWAL_UPLOAD_RESULT_FILE = 'UploadWithdrawResults';

	// SUBSCRIPTIONS (aka RECURRING_PURCHASES)
	public static RECURRING_PURCHASES = 'RecurringPurchases';
	public static RECURRING_PURCHASES_STATES = `${this.RECURRING_PURCHASES}/States`;

	// FreeTickets
	public static FREE_TICKETS_BAL = 'Balance';
	public static FREE_TICKETS_BAL_FOR_GAME = 'GameGroupHistory';

	// AWARD FREE TICKETS ACTIONS
	public static TOP_UP_CARDS_BATCHES = 'Batches';
	public static TOP_UP_CARDS_ACTIVATE = 'Activate';
	public static TOP_UP_CARDS_BATCHES_AGENT = 'RetailAgent';
	public static TOP_UP_CARDS_CANCEL = 'Cancel';
	public static TOP_UP_CARDS_DOWNLOAD = 'Download';
	public static TOP_UP_CARDS_CARDS = 'Cards';
	public static TOP_UP_CARDS_PAYMENT = 'Payments';

	// GLOBAL GATEWAY
}
